@tailwind base;
@tailwind components;
@tailwind utilities;


@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-5px, 5px);
  }
  40% {
    transform: translate(-5px, -5px);
  }
  60% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  100% {
    transform: translate(0);
  }
}

.animate-glitch {
  animation: glitch 0.3s cubic-bezier(.25, .46, .45, .94) both infinite;
}

.backface-hidden {
  backface-visibility: hidden;
}

.perspective-1000 {
  perspective: 1000px;
}

@keyframes digitize {
  0% {
    clip-path: inset(0 0 0 0);
  }
  10% {
    clip-path: inset(10% 0 0 0);
  }
  20% {
    clip-path: inset(20% 0 0 0);
  }
  30% {
    clip-path: inset(30% 0 0 0);
  }
  40% {
    clip-path: inset(40% 0 0 0);
  }
  50% {
    clip-path: inset(50% 0 0 0);
  }
  60% {
    clip-path: inset(60% 0 0 0);
  }
  70% {
    clip-path: inset(70% 0 0 0);
  }
  80% {
    clip-path: inset(80% 0 0 0);
  }
  90% {
    clip-path: inset(90% 0 0 0);
  }
  100% {
    clip-path: inset(100% 0 0 0);
  }
}

.digitize {
  animation: digitize 2s linear forwards;
}

.digitize::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 255, 0, 0.15),
    rgba(0, 255, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
  opacity: 0;
  animation: glitch 0.2s infinite;
}

@keyframes glitch {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.portal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.portal-circle {
  width: 0;
  height: 0;
  border-radius: 50%;
  background: radial-gradient(circle, #00ff00 0%, #004400 70%, transparent 100%);
  animation: portalOpen 2s forwards;
}

@keyframes portalOpen {
  0% {
    width: 0;
    height: 0;
    opacity: 0;
  }
  50% {
    width: 300px;
    height: 300px;
    opacity: 1;
  }
  100% {
    width: 200vmax;
    height: 200vmax;
    opacity: 0;
  }
}

.portal-open {
  animation: cardDisappear 1s forwards;
}

@keyframes cardDisappear {
  0% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
  100% {
    transform: scale(0) rotate(720deg);
    opacity: 0;
  }
}
